import React, { useEffect } from 'react';
import { trackPurchase } from '../misc/gtag';
import SEO from '../components/SEO';
import { isBrowser } from '../misc/utils';

function redirect() {
  if (window.self !== window.top) {
    window.top.location.replace('/page/thank-you');
  } else {
    window.location.replace('/');
  }
}

export default function Success() {
  useEffect(() => {
    if (!isBrowser) return;

    if (!window.location.search) {
      // eslint-disable-next-line no-console
      console.warn('No search params found, abort.');
      return;
    }

    const params = new URLSearchParams(window.location.search);

    trackPurchase({
      id: params.get('transaction_id'),
      total: parseFloat(params.get('total')),
      tax: parseFloat(params.get('vat')),
      currency: params.get('currency'),
      coupon: params.get('coupon'),
      items: [{
        name: String(params.get('product')),
        brand: 'LEI',
        quantity: 1,
        price: parseFloat(params.get('amount')),
      }],
    }).finally(() => {
      redirect();
    });
  }, []);

  return (
    <>
      <SEO title="Payment success" />
    </>
  );
}

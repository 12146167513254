/**
 * Track event
 * @param {string} name - Event name
 * @param {object} params - Event parameters
 * @param {number} [timeout=300] - Event timeout in ms
 */
export function trackEvent(name, params, timeout = 300) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => reject(), timeout);

    if (typeof window.gtag !== 'function') {
      clearTimeout(timer);
      reject();
    }

    window.gtag('event', name, {
      event_callback: () => {
        clearTimeout(timer);
        resolve();
      },
      ...params,
    });
  });
}

export function trackPurchase(data) {
  return trackEvent('purchase', {
    transaction_id: data.id,
    value: data.total,
    currency: data.currency,
    tax: data.tax,
    coupon: data.coupon || '',
    items: data.items,
  });
}
